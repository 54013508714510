import React, { FC } from 'react';

import styles from './promo-default.module.css';
import { Location, Promotion } from '../../utils/types';
import Header from '../header/header';

interface IPromoDefault {
  city: Location;
  onClickButtonOrder: () => void;
}

const PromoDefault: FC<IPromoDefault> = ({ city, onClickButtonOrder }) => {
  return (
    <section className={styles['promo-default'] + ' w-full overflow-hidden'}>
      <div className={styles['promo-default__title-button']}>
        <div className={styles['promo-default__subtitle'] + ' uppercase'}>
          <div className="promo-default__title-line">
            <strong>Мы знаем, как продлить службу</strong>{' '}
          </div>
          <div className="promo-default__title-line">
            <strong>вашего авто на долгие годы</strong>
          </div>
        </div>
        <div className={styles['promo-default__line']} />
        <div className={styles['promo-default__title'] + ''}>
          <div className={styles['promo-default__title-line']}>
            Диагностика за <span className={styles.selectedText}>0 ₽</span>
          </div>
          <div className="promo-default__title-line"></div>
        </div>
        <div className={styles['promo-default__description'] + ' '}>
          <div className="promo-default__title-line">
            Скидка на работы <span className={styles.selectedText}>-20%</span>
          </div>
        </div>
        <button
          className={styles['promo-default__button']}
          onClick={onClickButtonOrder}
        >
          ЗАПИСАТЬСЯ
        </button>
      </div>
      <div className={styles['promo-default__background']}></div>
      <div className={styles['promo-default__wheel']}></div>
    </section>
  );
};

export default PromoDefault;
